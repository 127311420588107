import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './'
import { setLoading, setError } from './Site';
import { MarketingResource } from '../models/lpc/MarketingResource';
import { LearningPartner } from '../models/LearningPartner';
import { TrainingEvent } from '../models/lpc/TrainingEvent';
import { Instructor } from '../models/lpc/Instructor';
import { AffiliationRequest } from '../models/lpc/AffiliationRequest';
import { DeliveryReadinessDocument } from '../models/lpc/DeliveryReadinessDocument';
import { LpcAffiliation } from '../models/lpc/LpcAffiliation';

export interface LPCState {
    deliveryReadinessDocuments?: DeliveryReadinessDocument[];
    learningPartners?: LearningPartner[];
    instructorPartners?: LearningPartner[];
    instructorTypes?: number[];
    marketingResources?: MarketingResource[];
    trainingEvents?: TrainingEvent[];
    instructors?: Instructor[];
    instructor?: Instructor;
    affiliations?: LpcAffiliation[];
}

let initialState: LPCState = {
}

export const lpcSlice = createSlice({
    name: 'LearningPartnerConnection',
    initialState,
    reducers: {
        setDeliveryReadinessDocuments: (state, action) => {
            state.deliveryReadinessDocuments = action.payload;
        },
        setLearningPartners: (state, action) => {
            state.learningPartners = action.payload;
        },
        setInstructorPartners: (state, action) => {
            state.instructorPartners = action.payload;
        },
        setInstructorTypes: (state, action) => {
            state.instructorTypes = action.payload;
        },
        setMarketingResources: (state, action) => {
            state.marketingResources = action.payload;
        },
        setTrainingEvents: (state, action) => {
            state.trainingEvents = action.payload;
        },
        setInstructors: (state, action) => {
            state.instructors = action.payload;
        },
        setInstructor: (state, action) => {
            state.instructor = action.payload;
        },
        setAffiliations: (state, action) => {
            state.affiliations = action.payload;
        }
    }
});

export const { setDeliveryReadinessDocuments, setLearningPartners, setInstructorTypes, setMarketingResources, setTrainingEvents,
    setInstructors, setInstructor, setAffiliations, setInstructorPartners } = lpcSlice.actions;

export const retrieveMarketingResources = (): AppThunk => async (dispatch, getState) => {
    let url = 'api/lpc/marketingResources';

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
        response => response.json()
    ).then(data => {
        dispatch(setMarketingResources(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export const retrieveDeliveryReadinessDocuments = (): AppThunk => async (dispatch, getState) => {
    let url = 'api/lpc/deliveryReadinessDocument';

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
        response => response.json()
    ).then(data => {
        dispatch(setDeliveryReadinessDocuments(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export const retrieveLearningPartners = (): AppThunk => async (dispatch, getState) => {
    let url = 'api/lpc/learningPartners';

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
        response => response.json()
    ).then(data => {
        dispatch(setLearningPartners(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export const retrieveInstructorPartners = (): AppThunk => async (dispatch, getState) => {
    let url = 'api/lpc/instructorPartners';

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
        response => response.json()
    ).then(data => {
        dispatch(setLoading(false));
        dispatch(setInstructorPartners(data));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export const retrieveTrainingEvents = (): AppThunk => async (dispatch, getState) => {
    let url = 'api/lpc/trainingEvents';

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
        response => response.json()
    ).then(data => {
        dispatch(setTrainingEvents(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export const retrieveInstructors = (learningPartner?: LearningPartner): AppThunk => async (dispatch, getState) => {
    let url = 'api/lpc/instructors';
    let state = getState();
    let user = state.site.user;
    let request = {
        user: user,
        learningPartner: learningPartner
    }

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
        response => response.json()
    ).then(data => {
        dispatch(setInstructors(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export const retrieveInstructor = (): AppThunk => async (dispatch, getState) => {
    let url = 'api/lpc/instructor';
    let state = getState();
    let userInfo = state.site.userInfo;
    let user = state.site.user;
    let isImpersonating = state.site.actualUser && state.site.user?.email !== state.site.actualUser?.email;

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify({
            email: isImpersonating ? user?.email : userInfo?.email,
            oktaId: !isImpersonating ? userInfo?.sub : undefined
        }),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res => {
        return (res.status == 204)
            ? undefined
            : res.json();
    }).then(data => {
        dispatch(setInstructor(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export const retrieveAffiliations = (): AppThunk => async (dispatch, getState) => {
    let url = 'api/lpc/affiliations';
    let state = getState();
    let user = state.site.user;

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(user),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
        response => response.json()
    ).then(data => {
        dispatch(setAffiliations(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export const saveAffiliations = (affiliations: LpcAffiliation[]): AppThunk => async (dispatch, getState) => {
    let url = 'api/lpc/saveAffiliations';
    let state = getState();
    let user = state.site.user;
    let request = {
        user: user,
        affiliations: affiliations
    }

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
        response => response.json()
    ).then(data => {
        if (data?.statusCode) {
            throw Error(data.message);
        }
        dispatch(setAffiliations(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export const downloadResourceFile = (resource: MarketingResource): AppThunk => async (dispatch, getState) => {
    let url = 'api/lpc/downloadResourceFile';
    let request = {
        documentType: resource.type,
        document: resource.path
    };
    let fileName = "";

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            Accept: "application/json, application/zip, application/pdf",
            "Content-Type": "application/json"
        }
    }).then(res => {
        if (res.ok) {
            let header = res.headers.get('Content-Disposition');
            let parts = header!.split(';');

            fileName = parts[1].split('=')[1];

            return res.blob();
        }
        return res.json();
    }).then(blob => {
        if (blob.message || blob.statusCode || blob.errors) {
            throw new Error(blob.message ?? blob);
        }

        let a = $("<a style='display: none;'/>");
        let url = window.URL.createObjectURL(blob);

        dispatch(setLoading(false));
        a.attr("href", url);
        a.attr("download", fileName);
        $("body").append(a);
        a[0].click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export const downloadDrd = (drd: DeliveryReadinessDocument): AppThunk => async (dispatch, getState) => {
    let url = 'api/lpc/downloadDrd';
    let fileName = "";

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(drd),
        headers: {
            Accept: "application/json, application/pdf",
            "Content-Type": "application/json"
        }
    }).then(res => {
        if (res.ok) {
            let header = res.headers.get('Content-Disposition');
            let parts = header!.split(';');

            fileName = parts[1].split('=')[1];

            return res.blob();
        }
        return res.json();
    }).then(blob => {
        if (blob.message || blob.statusCode || blob.errors) {
            throw new Error(blob.message ?? blob);
        }

        let a = $("<a style='display: none;'/>");
        let url = window.URL.createObjectURL(blob);

        dispatch(setLoading(false));
        a.attr("href", url);
        a.attr("download", fileName);
        $("body").append(a);
        a[0].click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export const downloadDocument = (document: string): AppThunk => async (dispatch, getState) => {
    let url = 'api/lpc/downloadDocument';
    let fileName = "";
    let request = {
        documentType: 0,
        document: document
    };

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            Accept: "application/json, application/zip, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "Content-Type": "application/json"
        }
    }).then(res => {
        if (res.ok) {
            let header = res.headers.get('Content-Disposition');
            let parts = header!.split(';');

            fileName = parts[1].split('=')[1];

            return res.blob();
        }
        return res.json();
    }).then(blob => {
        if (blob.message || blob.statusCode || blob.errors) {
            throw new Error(blob.message ?? blob);
        }

        let a = $("<a style='display: none;'/>");
        let url = window.URL.createObjectURL(blob);

        dispatch(setLoading(false));
        a.attr("href", url);
        a.attr("download", fileName);
        $("body").append(a);
        a[0].click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export const saveAffiliationRequest = (registration: AffiliationRequest, callback: Function): AppThunk => async (dispatch, getState) => {
    let url = 'api/lpc/saveAffiliationRequest';

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(registration),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
        response => response.json()
    ).then(data => {
        if (data?.statusCode) {
            throw Error(data.message);
        }
        dispatch(setLoading(false));
        callback();
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export const saveInstructor = (instructor: Instructor): AppThunk => async (dispatch, getState) => {
    let url = 'api/lpc/saveInstructor';

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(instructor),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
        response => response.json()
    ).then(data => {
        if (data?.statusCode) {
            throw Error(data.message);
        }
        dispatch(setInstructor(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export const exportInstructors = (exportRequest: any): AppThunk => async (dispatch, getState) => {
    let url = 'api/lpc/exportInstructors';
    let fileName = "";

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(exportRequest),
        headers: {
            Accept: "application/json, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "Content-Type": "application/json"
        }
    }).then(res => {
        if (res.ok) {
            let header = res.headers.get('Content-Disposition');
            let parts = header!.split(';');

            fileName = parts[1].split('=')[1];

            return res.blob();
        }
        return res.json();
    }).then(blob => {
        if (blob.message || blob.statusCode || blob.errors) {
            throw new Error(blob.message ?? blob);
        }

        let a = $("<a style='display: none;'/>");
        let url = window.URL.createObjectURL(blob);

        dispatch(setLoading(false));
        a.attr("href", url);
        a.attr("download", fileName);
        $("body").append(a);
        a[0].click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export const exportAffiliations = (): AppThunk => async (dispatch, getState) => {
    let url = 'api/lpc/exportAffiliations';
    let fileName = "";
    let state = getState();
    let user = state.site.user;

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(user),
        headers: {
            Accept: "application/json, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "Content-Type": "application/json"
        }
    }).then(res => {
        if (res.ok) {
            let header = res.headers.get('Content-Disposition');
            let parts = header!.split(';');

            fileName = parts[1].split('=')[1];

            return res.blob();
        }
        return res.json();
    }).then(blob => {
        if (blob.message || blob.statusCode || blob.errors) {
            throw new Error(blob.message ?? blob);
        }

        let a = $("<a style='display: none;'/>");
        let url = window.URL.createObjectURL(blob);

        dispatch(setLoading(false));
        a.attr("href", url);
        a.attr("download", fileName);
        $("body").append(a);
        a[0].click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export const exportUnaffiliated = (): AppThunk => async (dispatch, getState) => {
    let url = 'api/lpc/exportUnaffiliatedInstructors';
    let fileName = "";

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
            Accept: "application/json, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "Content-Type": "application/json"
        }
    }).then(res => {
        if (res.ok) {
            let header = res.headers.get('Content-Disposition');
            let parts = header!.split(';');

            fileName = parts[1].split('=')[1];

            return res.blob();
        }
        return res.json();
    }).then(blob => {
        if (blob.message || blob.statusCode || blob.errors) {
            throw new Error(blob.message ?? blob);
        }

        let a = $("<a style='display: none;'/>");
        let url = window.URL.createObjectURL(blob);

        dispatch(setLoading(false));
        a.attr("href", url);
        a.attr("download", fileName);
        $("body").append(a);
        a[0].click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export default lpcSlice.reducer;
