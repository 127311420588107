import React from 'react';
import { Box, Menu, Nav, ResponsiveContext, Button, DropButton, Header, Layer, Text, Paragraph, Footer } from 'grommet';
import { Menu as MenuIcon } from 'grommet-icons';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { push, replace } from 'connected-react-router';
import { retrieveAvailableLanguages, retrieveMenuStrings, retrieveLocalizedStrings, login, siteSlice } from '../store/Site';
import { ApplicationState } from '../store';
import { SelectOption } from '../models/Common';
import classNames from 'classnames';
import { hasRole, isPLMTAdmin, isPLMTBuilderAdmin } from '../Utilities'
import { withOktaAuth } from '@okta/okta-react';
import _ from 'lodash';
import Login from './Login';
import LanguageMenu from './LanguageMenu';

import './NavMenu.css';

const mapDispatchToProps = {
    push,
    replace,
    login,
    retrieveAvailableLanguages,
    retrieveMenuStrings,
    retrieveLocalizedStrings,
    ...siteSlice.actions
}

type NavMenuProps =
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

interface NavMenuState {
    displayQuestion: boolean;
}

class NavMenu extends React.PureComponent<NavMenuProps, NavMenuState> {

    constructor(props: NavMenuProps) {
        super(props);

        this.state = {
            displayQuestion: false
        };
    }

    public componentDidMount() {
        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let langCode = langInfo[0];
        let location = window.location;
        let currentPath = _.replace(location.pathname, /\/tr/i, '');
        let pathInfo = currentPath.split('/');
        let hrefLanguage = pathInfo.length >= 2 ? pathInfo[1] : pathInfo[0];

        langCode = hrefLanguage.length == 2 ? hrefLanguage : langCode;
        this.props.retrieveAvailableLanguages(langCode);
        this.loadMenuStrings(langCode);
    }

    gotoUrl = (url: string) => {
        this.props.push(url);
    }

    loadMenuStrings = (lang: string) => {
        let keys = ['Datacard.Overview_Label', 'Datacard.Get_Started_Label', 'Datacard.Training_Label', 'Datacard.Certification_Label',
            'Datacard.Resources_Label', 'ExternalPages.Site_Language', 'Datacard.Get_Started_Customer_Label', 'Datacard.Get_Started_Partner_Label',
            'Datacard.Get_Started_HPE_Employees_Label', 'Datacard.Training_Calendar_Label', 'Datacard.Training_Calendar_Partners_Label',
            'Datacard.View_All_Courses_Label', 'Datacard.Course_Skill_Levels_Label', 'Datacard.Learning_Paths_Label',
            'Datacard.Learner_ID_Look_Up_Tool_Label', 'ExternalPages.Help_Label', 'Datacard.Exams_Label', 'ExternalPages.Loading_Label',
            'Datacard.HPE_Press_Books_Label', 'Datacard.View_All_Certifications_Label', 'Datacard.View_Sales_Certifications_Label',
            'Datacard.View_InactiveExpiringExpired_Certifications_Label', 'Datacard.Exam_ViewAll_Label', 'Datacard.Certification_Paths_Label',
            'Datacard.Portfolios_Label', 'Datacard.Certification_Program_Overview_Label', 'Datacard.Steps_To_Certification_Label',
            'Datacard.SuccessStories_Label', 'Datacard.Recertification_Label', 'Datacard.HPE_Learner_ID_Label', 'Datacard.Learning_Center_Label',
            'Datacard.Assist_Kit_Label', 'Datacard.Benefits_Label', 'ExternalPages.TrainingPartner_Label', 'Datacard.Site_Map_Label',
            'ExternalPages.Contact_Support_Label', 'ExternalPages.Menu_Search_For_Training', 'ExternalPages.Menu_Find_Class',
            'ExternalPages.Navigation_Technical', 'ExternalPages.Navigation_Sales', 'ExternalPages.Navigation_CommunitiesPrograms',
            'ExternalPages.Navigation_FollowUs', 'ExternalPages.Navigation_Overview', 'ExternalPages.Tech_Pro_Label', 'ExternalPages.Videos_Label',
            'ExternalPages.Overview_GetCertified', 'ExternalPages.Overview_MoreLearning', 'ExternalPages.Navigation_Technical_ScheduleExam',
            'ExternalPages.Navigation_Technical_KeepCurrent', 'ExternalPages.Navigation_Showcase', 'ExternalPages.Navigation_Sales_Join',
            'ExternalPages.Navigation_Resources_Brochure', 'ExternalPages.Navigation_Resources_BuyVoucher', 'ExternalPages.Navigation_FollowUs_Forum',
            'ExternalPages.Navigation_FollowUs_Blog', 'ExternalPages.Navigation_FollowUs_LinkedIn', 'ExternalPages.Navigation_FollowUs_Twitter',
            'ExternalPages.Menu_Self_Directed_Learning', 'ExternalPages.Contact_Security_Label', 'ExternalPages.Credentials_ViewAll_Label',
            'ExternalPages.MasterAseAdvantage_Label', 'ExternalPages.Login_Label', 'ExternalPages.Logout_Label',
            'ExternalPages.Login_Tip', 'ExternalPages.Credentials_Label', 'ExternalPages.Continuous_Learning_Label',
            'ExternalPages.SME_Administrator', 'ExternalPages.PLMT_Label', 'ExternalPages.SME_Applicant', 'ExternalPages.AdminTools_Label',
            'ExternalPages.LearningPartnerInstructors_Label', 'ExternalPages.MyProfile_Label', 'ExternalPages.TraningHistory_Label',
            'ExternalPages.ECertificatesIDCard_Label', 'ExternalPages.AreasOfInterest_Label', 'ExternalPages.CertificationLevel_Label',
            'ExternalPages.LearningPartnerConnection_Label', 'ExternalPages.CertifiedInstructorConnection_Label', 'ExternalPages.ManageBuilder_Label',
            'ExternalPages.SwitchUser_Label', 'ExternalPages.My_Certification_Paths_Label', 'ExternalPages.Certifications_Label',
            'ExternalPages.Transcript_Label', 'ExternalPages.Assignments_Label', 'ExternalPages.Credentials', 'ExternalPages.MyCertificationPlan_Label',
            'ExternalPages.Yes_Label', 'ExternalPages.No_Label', 'ExternalPages.TeamMember_Question', 'MyLearning.LearnerId_Lookup_Label',
            'ExternalPages.CertificationsAdvantage_Label', 'ExternalPages.Aruba_Airheads_Label', 'ExternalPages.UnaffiliatedLearnerRegistration_Label',
            'ExternalPages.CertificationDistinction_Label', 'ExternalPages.ManageAffiliations_label'];

        this.props.retrieveMenuStrings(keys, lang);
    }

    onLanguageChange = (lang: SelectOption) => {
        let site = this.props.site;
        let location = window.location;
        let currentPath = _.replace(location.pathname, /\/tr/i, '');
        let pathInfo = currentPath.split('/');
        let langInfo = lang.value.split('-');
        let urlLanguage = pathInfo.length >= 2 ? pathInfo[1] : pathInfo[0];
        let selectedLang = site.availableLanguages
            ? site.availableLanguages.find((av) => {
                return av.value.startsWith(urlLanguage);
            })
            : null;
        let basePath = (selectedLang ? currentPath.replace(`${urlLanguage}`, '') : currentPath).replace('//', '/');

        let newPath: any = lang.value === 'en-US'
            ? `${basePath}`
            : `/${langInfo[0]}${basePath}`;

        if (currentPath !== newPath) {
            let localizedStrings = Object.keys(site.localizedStrings);

            this.props.setCurrentLanguage(lang);
            this.loadMenuStrings(langInfo[0]);
            this.props.retrieveLocalizedStrings(localizedStrings, langInfo[0]);
            this.props.push(newPath, this.props.site);
        }
    }

    onDisplayQuestion = () => {
        this.setState({ displayQuestion: true });
    }

    onCertPlan = (isEmployee: boolean) => {
        this.setState({ displayQuestion: false });
        if (!isEmployee) {
            window.open("https://hpe-external.sabacloud.com/Saba/Web_spf/HPE/app/me/plans", "_blank");
        }
        else {
            window.open("https://hpe.sabacloud.com/Saba/Web_spf/HPE/app/me/plans", "_blank");
        }
    }

    renderMenus = (collapsed: boolean) => {
        let menus = [];
        let site = this.props.site;
        let user = this.props.site.user;
        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : site.currentLanguage.value ?? "en-US";
        let langInfo = urlLanguage.split('-');
        let location = window.location;
        let currentPath = _.replace(location.pathname, /\/tr/i, '');
        let pathInfo = currentPath.split('/');
        let hrefLanguage = pathInfo.length >= 2 ? pathInfo[1] : pathInfo[0];
        let langCode = hrefLanguage.length == 2 ? hrefLanguage : langInfo[0];
        let localUrl = langCode !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langCode}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let languageUrl = langCode !== 'en'
            ? `/${langCode}`
            : '';
        let isAvailable = false;
        let profileUrl = `${process.env.REACT_APP_PROFILE_SITE}`;
        let resourceItems = [];
        let isSmeAdmin = false;
        let isPlmtAdmin = false;
        let hasLearnerId = false;
        let hasPlmtAccess = false;
        let isPpa = false;
        let adminItems = [];

        if (collapsed) {
            menus.push(<Button key="overview-menu" fill alignSelf="center" href={`${localUrl}/`}
                style={{ fontWeight: 'bold', textDecoration: 'none', color: 'black' }}
                className={classNames({ "active-menu": site.activeMenu === 'overview-menu' })} size="small"
                label={site.menuStrings['Datacard.Overview_Label']} />);
        }
        else {
            menus.push(<Button key="overview-menu" href={`${localUrl}/`}
                style={{ fontWeight: 'bold', textDecoration: 'none', color: 'black' }} hoverIndicator
                className={classNames({ "active-menu": site.activeMenu === 'overview-menu' })} size="small"
                label={site.menuStrings['Datacard.Overview_Label']} />);
        }
        if (!user) {
            menus.push(<Menu key="getstarted-menu" label={site.menuStrings['Datacard.Get_Started_Label']} size='small'
                className={classNames({ "active-menu": site.activeMenu === 'getstarted-menu' })}
                items={[
                    { label: `${site.menuStrings['Datacard.Get_Started_Label']}`, href: `${localUrl}/getstarted/partners` },
                    { label: `${site.menuStrings['Datacard.HPE_Learner_ID_Label']}`, href: `${localUrl}/getstarted` }
                ]} />);
        }
        // else {
        //     menus.push(<Menu key="profile-menu" label={site.menuStrings['ExternalPages.MyProfile_Label']} size='small'
        //         className={classNames({ "profile-menu": site.activeMenu === 'profile-menu' })}
        //         items={[
        // { label: `${site.menuStrings['ExternalPages.Certifications_Label']}`, href: `${profileUrl}/certifications` },
        // { label: `${site.menuStrings['ExternalPages.Continuous_Learning_Label']}`, href: `${profileUrl}/continuous-learning` },
        // { label: `${site.menuStrings['ExternalPages.Credentials_Label']}`, href: `${profileUrl}/credentials` },
        // { label: `${site.menuStrings['ExternalPages.Transcript_Label']}`, href: `${profileUrl}/my-transcript` },
        // { label: `${site.menuStrings['ExternalPages.Assignments_Label']}`, href: `${profileUrl}/assignment` },
        // { label: `${site.menuStrings['ExternalPages.AreasOfInterest_Label']}`, href: `${profileUrl}/areas-of-interest` }
        //         ]} />);
        // }
        menus.push(<Menu key="training-menu" label={site.menuStrings['Datacard.Training_Label']} size='small'
            className={classNames({ "active-menu": site.activeMenu === 'training-menu' })}
            items={[
                { label: `${site.menuStrings['ExternalPages.Menu_Search_For_Training']}`, href: `${localUrl}/TrainingCalendar` },
                { label: `${site.menuStrings['Datacard.View_All_Courses_Label']}`, href: `${localUrl}/courses` },
                { label: `${site.menuStrings['ExternalPages.Menu_Find_Class']}`, href: `${localUrl}/LearningPartners` },
                { label: `${site.menuStrings['ExternalPages.Menu_Self_Directed_Learning']}`, href: `${localUrl}/hpepress` }
            ]} />);
        menus.push(<Menu key="technical-certification-menu" label={site.menuStrings['ExternalPages.Navigation_Technical']} size='small'
            className={classNames({ "active-menu": site.activeMenu === 'technical-certification-menu' })}
            items={[
                { label: `${site.menuStrings['Datacard.View_All_Certifications_Label']}`, href: `${localUrl}/certifications` },
                { label: `${site.menuStrings['ExternalPages.Credentials_ViewAll_Label']}`, href: `${localUrl}/credentials` },
                { label: `${site.menuStrings['Datacard.Exam_ViewAll_Label']}`, href: `${localUrl}/exams` },
                { label: `${site.menuStrings['ExternalPages.Navigation_Overview']}`, href: `${localUrl}/technical-certifications` },
                { label: `${site.menuStrings['ExternalPages.Tech_Pro_Label']}`, href: `${localUrl}/hpe-tech-pro` },
                { label: `${site.menuStrings['ExternalPages.Overview_GetCertified']}`, href: `${localUrl}/get-certified` },
                { label: `${site.menuStrings['ExternalPages.Navigation_Technical_ScheduleExam']}`, href: `${localUrl}/certification-exams` },
                { label: `${site.menuStrings['ExternalPages.Navigation_Technical_KeepCurrent']}`, href: `${localUrl}/keep-current` },
                { label: `${site.menuStrings['ExternalPages.Navigation_Showcase']}`, href: `${localUrl}/technical-certifications-badges` },
                {
                    label: `${site.menuStrings['ExternalPages.MyCertificationPlan_Label']}`,
                    onClick: this.onDisplayQuestion
                },
                {
                    label: `${site.menuStrings['Datacard.Certification_Paths_Label']}`, target: "_blank",
                    href: `${localUrl}/documents/certificationPath/${langCode === 'ja'
                        ? "CertificationPaths_A4.pdf"
                        : "CertificationPaths_Letter.pdf"}`
                }
            ]} />);
        menus.push(<Menu key="sales-certification-menu" label={site.menuStrings['ExternalPages.Navigation_Sales']} size='small'
            className={classNames({ "active-menu": site.activeMenu === 'sales-certification-menu' })}
            items={[
                { label: `${site.menuStrings['Datacard.View_All_Certifications_Label']}`, href: `${localUrl}/certifications?credentialView=Sales Certifications` },
                { label: `${site.menuStrings['ExternalPages.Navigation_Overview']}`, href: `${localUrl}/sales-certifications` },
                { label: `${site.menuStrings['ExternalPages.Navigation_Sales_Join']}`, href: `${localUrl}/hpe-sales-pro` },
                { label: `${site.menuStrings['ExternalPages.Navigation_Showcase']}`, href: `${localUrl}/sales-certifications-badges` }
            ]} />);
        menus.push(<Menu key="communities-programs-menu" label={site.menuStrings['ExternalPages.Navigation_CommunitiesPrograms']} size='small'
            className={classNames({ "active-menu": site.activeMenu === 'communities-programs-menu' })}
            items={[
                { label: `${site.menuStrings['ExternalPages.Overview_MoreLearning']}`, href: `${localUrl}/overview/more-learning` },
                { label: `${site.menuStrings['Datacard.HPE_Press_Books_Label']}`, href: `${localUrl}/hpepress` },
                {
                    label: `${site.menuStrings['ExternalPages.MasterAseAdvantage_Label']}`, target: '_blank',
                    href: 'https://www.hpe.com/psnow/doc/a00127863eew'
                },
                { label: `${site.menuStrings['ExternalPages.CertificationsAdvantage_Label']}`, href: `${localUrl}/certifications-advantage` },
                { label: `${site.menuStrings['ExternalPages.CertificationDistinction_Label']}`, href: `${localUrl}/certification-distinction` },
                { label: 'HPE Tech Pro', target: '_blank', href: 'https://techpro.hpe.com' },
                { label: `${site.menuStrings['ExternalPages.Aruba_Airheads_Label']}`, target: '_blank', href: 'https://community.arubanetworks.com/home' }
            ]} />);
        resourceItems.push({ label: `${site.menuStrings['ExternalPages.MyCertificationPlan_Label']}`, onClick: this.onDisplayQuestion },
            { label: `${site.menuStrings['Datacard.HPE_Press_Books_Label']}`, href: `${localUrl}/hpepress` },
            { label: `${site.menuStrings['ExternalPages.Navigation_Resources_Brochure']}`, href: 'https://www.hpe.com/psnow/doc/4aa4-2093enw', target: '_blank' },
            { label: `${site.menuStrings['ExternalPages.Navigation_Resources_BuyVoucher']}`, href: `${localUrl}/certification-exams/vouchers` },
            {
                label: `${site.menuStrings['ExternalPages.Videos_Label']}`, target: '_blank',
                href: ' https://www.hpe.com/h22228/video-gallery/us/en/3fe68389-63cb-4a54-a677-d497764bb55b/' +
                    'hpe-technical-certifications---testimonials--short-version-/video/playlistId=1743313352417774529'
            });
        if (!user) {
            menus.push(<Menu key="follow-us-menu" label={site.menuStrings['ExternalPages.Navigation_FollowUs']} size='small'
                className={classNames({ "active-menu": site.activeMenu === 'follow-us-menu' })}
                items={[
                    {
                        label: `${site.menuStrings['ExternalPages.Navigation_FollowUs_Forum']}`, target: "_blank",
                        href: "https://community.hpe.com/t5/Partner-Certification/ct-p/PartnerCertification"
                    },
                    {
                        label: `${site.menuStrings['ExternalPages.Navigation_FollowUs_Blog']}`, target: "_blank",
                        href: "https://community.hpe.com/t5/Advancing-Life-Work/bg-p/company"
                    },
                    {
                        label: `${site.menuStrings['ExternalPages.Navigation_FollowUs_LinkedIn']}`, target: "_blank",
                        href: "https://www.linkedin.com/groups/1336927/"
                    }
                ]} />);
        }
        else {
            let isInstructor = hasRole(user, ['INSTRUCTOR', 'CERTIFIED_INSTRUCTOR']);
            let isLearningPartner = hasRole(user, ['LEARNING_PARTNER']);
            let isBuilderAdmin = isPLMTBuilderAdmin(user);
            let isCicAdmin = hasRole(user, ['CIC_ADMIN']);
            let isLpcAdmin = hasRole(user, ['LPC_ADMIN']);

            isSmeAdmin = hasRole(user, ['SME_ADMIN']);
            isPlmtAdmin = isPLMTAdmin(user);
            hasLearnerId = hasRole(user, ["LEARNER_ID_ADMIN"]);
            isPpa = hasRole(user, ["PPA"]);
            hasPlmtAccess = hasRole(user, ["PEM", "PEM_Backup", "PPA"]);
            if (isInstructor || isCicAdmin) {
                resourceItems.push({
                    label: `${site.menuStrings['ExternalPages.CertifiedInstructorConnection_Label']}`,
                    href: `${localUrl}/cic`
                });
            }
            if (isInstructor || (isLearningPartner && isPpa) || isLpcAdmin) {
                resourceItems.push({
                    label: `${site.menuStrings['ExternalPages.LearningPartnerConnection_Label']}`,
                    href: `${localUrl}/lpc`
                });
            }
            if (hasPlmtAccess || isPlmtAdmin) {
                adminItems.push({ label: `${site.menuStrings['ExternalPages.PLMT_Label']}`, href: `${localUrl}/plmt` });
            }
            if (isSmeAdmin) {
                adminItems.push({ label: `${site.menuStrings['ExternalPages.SME_Administrator']}`, href: `${localUrl}/sme/admin` });
            }
            if (isBuilderAdmin) {
                adminItems.push({ label: `${site.menuStrings['ExternalPages.ManageBuilder_Label']}`, href: `${localUrl}/plmt/manage-builder` });
            }
            if (hasLearnerId) {
                adminItems.push({ label: `${site.menuStrings['MyLearning.LearnerId_Lookup_Label']}`, href: `${localUrl}/admin/learner-lookup` });
            }
        }
        menus.push(<Menu key="resources-menu" label={site.menuStrings['Datacard.Resources_Label']} size='small'
            className={classNames({ "active-menu": site.activeMenu === 'resources-menu' })} items={resourceItems} />);
        if (isSmeAdmin || hasPlmtAccess || isPlmtAdmin || hasLearnerId) {
            menus.push(<Menu key="admin-tools-menu" label={site.menuStrings['ExternalPages.AdminTools_Label']} size='small'
                className={classNames({ "active-menu": site.activeMenu === 'admin-tools-menu' })}
                items={adminItems} />);
        }
        menus.push(<Menu key="help-menu" label={site.menuStrings['ExternalPages.Help_Label']} size='small'
            className={classNames({ "active-menu": site.activeMenu === 'help-menu' })}
            items={[
                { label: `${site.menuStrings['ExternalPages.Contact_Support_Label']}`, href: `${localUrl}/support` },
                { label: `${site.menuStrings['ExternalPages.Contact_Security_Label']}`, href: `${localUrl}/contact-security` },
                { label: `${site.menuStrings['Datacard.Assist_Kit_Label']}`, href: `${localUrl}/assistkit` },
                {
                    label: `${site.menuStrings['Datacard.Site_Map_Label']}`,
                    href: `${localUrl}/sitemap`
                }
            ]} />);
        if (!user) {
            menus.push(<LanguageMenu key='lang-comp' />);
        }
        menus.push(<Login key='login-comp' />);

        return menus;
    }

    public render() {
        let collapsedSizes = ['medium', 'small', 'medium_small', 'medium_xxsmall', 'xsmall'];
        let site = this.props.site;

        return (
            <Header>
                <ResponsiveContext.Consumer>
                    {size => {
                        return this.props.site.menusLoaded &&
                            ((collapsedSizes.indexOf(size) !== -1)
                                ? <Box>
                                    <DropButton alignSelf="end" icon={<MenuIcon color="#0D5265" size="medium" />}
                                        dropAlign={{ top: 'bottom', right: 'right' }}
                                        dropContent={
                                            <Box align="center">
                                                {this.renderMenus(true)}
                                            </Box>
                                        } />
                                </Box>
                                : <Nav direction="row" pad={{ top: "xsmall", bottom: "small" }} align="center" fill="horizontal">
                                    {this.renderMenus(false)}
                                </Nav>)
                    }}
                </ResponsiveContext.Consumer>
                {this.state.displayQuestion && <Layer>
                    <Box width={{ max: "large" }} gap="small">
                        <Box pad="small">
                            <Paragraph fill textAlign="center">
                                {site.menuStrings['ExternalPages.TeamMember_Question']}
                            </Paragraph>
                        </Box>
                        <Footer fill="horizontal" pad="small" justify="end">
                            <Box direction="row-responsive" gap="small" alignSelf="end">
                                <Button primary label={site.menuStrings['ExternalPages.Yes_Label']} onClick={() => this.onCertPlan(true)} />
                                <Button secondary label={site.menuStrings['ExternalPages.No_Label']}
                                    onClick={() => { this.onCertPlan(false) }} />
                            </Box>
                        </Footer>
                    </Box>
                </Layer>}
            </Header>
        );
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(withOktaAuth(NavMenu as any)));
