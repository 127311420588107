import * as React from 'react';
import { Route, RouteComponentProps, Switch, Redirect, withRouter } from 'react-router-dom';
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { ApplicationState } from './store';
import { connect } from 'react-redux';
import { Box, Button, Paragraph, Anchor } from 'grommet';
import { ClearBrowserCache } from 'react-clear-browser-cache';
import { replace } from 'connected-react-router';
import Layout from './components/Layout';
import Certification from './pages/datacard/Certification';
import Course from './pages/datacard/Course';
import Exam from './pages/datacard/Exam';
import UpgradePath from './pages/datacard/UpgradePath';
import Calendar from './pages/Calendar';
import Certifications from './pages/list/Certifications';
import Courses from './pages/list/Courses';
import Credentials from './pages/list/Credentials';
import LearningPartners from './pages/list/LearningPartners';
import Exams from './pages/list/Exams';
import Overview from './pages/Overview';
import GetStarted from './pages/GetStarted';
import Support from './pages/Support';
import HpePress from './pages/HpePress';
import AssistKit from './pages/AssistKit';
import TechnicalCertifications from './pages/TechnicalCertifications';
import SalesCertifications from './pages/SalesCertifications';
import CertificationExams from './pages/CertificationExams';
import SuccessStories from './pages/SuccessStories';
import ExamSecurity from './pages/ExamSecurity';
import Sitemap from './pages/Sitemap';
import SmeAdmin from './pages/sme/Admin';
import SmeApplication from './pages/sme/Application';
import PlmtHome from './pages/plmt/Home';
import PlmtActivities from './pages/plmt/Activities';
import PlmtGapAnalysis from './pages/plmt/GapAnalysis';
import PlmtGapSummary from './pages/plmt/GapSummary';
import LearnerIdLookup from './pages/LearnerIdLookup';
import LearningAssignment from './pages/plmt/LearningAssignment';
import RegionalReports from './pages/plmt/RegionalReports';
import MapReports from './pages/plmt/MapReports';
import Distributors from './pages/plmt/Distributors';
import ManageBuilder from './pages/plmt/ManageBuilder';
import CertificationsAdvantage from './pages/CertificationsAdvantage';
import CertifiedInstructorConnection from './pages/CertifiedInstructorConnection';
import LearningPartnerConnection from './pages/LearningPartnerConnection';
import AffiliationManagement from './pages/lpc/AffiliationManagement';
import InstructorRegistration from './pages/lpc/InstructorRegistration';
import InstructorFinder from './pages/lpc/InstructorFinder';
import UnaffiliatedLearnerReg from './pages/UnaffiliatedLearnerReg';
import LocateLearnerId from './pages/LocateLearnerId';
import CertificationDistinction from './pages/CertificationDistinction';

import './custom.css'

interface IAppState {
  oktaAuth: OktaAuth;
  urlSet: boolean;
}

const mapDispatchToProps = {
  replace
}

type AppProps =
  ApplicationState // ... state we've requested from the Redux store
  & typeof mapDispatchToProps
  & RouteComponentProps<any>; // ... plus incoming routing parameters

class App extends React.PureComponent<AppProps, IAppState> {
  constructor(props: AppProps) {
    super(props);

    let redirectUri = `${process.env.REACT_APP_OKTA_BASE_REDIRECT_URI}${process.env.PUBLIC_URL}/login/callback`;
    let oktaAuth = new OktaAuth({
      issuer: process.env.REACT_APP_OKTA_ISSUER,
      clientId: process.env.REACT_APP_OKTA_CLIENTID,
      redirectUri: redirectUri,
      scopes: ['openid', 'profile', 'email', 'address', 'phone', 'offline_access'],
      responseType: ['id_token', 'token', 'refresh_token'],
      tokenManager: {
        autoRenew: true
      },
      services: {
        autoRenew: true
      },
      pkce: true
    });
    this.state = {
      oktaAuth: oktaAuth,
      urlSet: false
    };
  }

  restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
    let baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
    let originalUriWithoutBasepath = baseUrl.length > 1 && baseUrl.endsWith('/')
      ? originalUri.replace(baseUrl.substring(0, baseUrl.lastIndexOf('/')), '')
      : originalUri;
    let relativeUrl = toRelativeUrl(originalUriWithoutBasepath || "/", window.location.origin);

    if (!this.state.urlSet) {
      this.setState({ urlSet: true });
      this.props.replace(relativeUrl);
    }
  }

  public render() {
    return (<ClearBrowserCache>{(contextValue: any) => {
      return (<Security oktaAuth={this.state.oktaAuth} restoreOriginalUri={this.restoreOriginalUri}>
        <Layout>
          {!contextValue.isLatestVersion && <Box direction="row" height={{ max: "small" }} elevation="xsmall" pad="xsmall" gap="xsmall" background="brand">
            <Paragraph fill={true} size="medium" margin="small" color="white">
              A new version of the application has been found.  This page will reload.
          </Paragraph>
          </Box>}
          <Switch>
            <Route path="/login/callback" component={LoginCallback} />
            <Route path='/:lang(..)?/datacard/certification/:id' component={Certification} />
            <Route path='/:lang(..)?/datacard/course/:id' component={Course} />
            <Route path='/:lang(..)?/datacard/exam/:id' component={Exam} />
            <Route path='/:lang(..)?/datacard/upgradepath/:id' component={UpgradePath} />
            <Route path='/:lang(..)?/trainingcalendar' component={Calendar} />
            <Route path='/:lang(..)?/certifications' component={Certifications} />
            <Route path='/:lang(..)?/credentials' component={Credentials} />
            <Route path='/:lang(..)?/courses' component={Courses} />
            <Route path='/:lang(..)?/exams' component={Exams} />
            <Route path='/:lang(..)?/learningPartners' component={LearningPartners} />
            <Route path='/:lang(..)?/contact-security' component={ExamSecurity} />
            <Route path='/:lang(..)?/overview/:activeTab?' component={Overview} />
            <Route path='/:lang(..)?/getstarted/:activeTab?' component={GetStarted} />
            <Route path='/:lang(..)?/hpepress' component={HpePress} />
            <Route path='/:lang(..)?/support/:profile?' component={Support} />
            <Route path='/:lang(..)?/assistkit' component={AssistKit} />
            <Route path='/:lang(..)?/success-stories' component={SuccessStories} />
            <Route path='/:lang(..)?/certification-exams/:activeTab?' component={CertificationExams} />
            <Route path='/:lang(..)?/sitemap' component={Sitemap} />
            <Route path='/:lang(..)?/technical-certifications/:activeTab?' component={TechnicalCertifications} />
            <Route path='/:lang(..)?/certifications-advantage' component={CertificationsAdvantage} />
            <Route path='/:lang(..)?/sales-certifications/:activeTab?' component={SalesCertifications} />
            <Route path='/:lang(..)?/certification-distinction' component={CertificationDistinction} />

            <Redirect from="/:lang(..)?/salesCertifications" to={{ pathname: '/:lang(..)?/certifications', search: "credentialView=Sales Certifications" }} />
            <Redirect from="/:lang(..)?/certifications/credentials" to={{ pathname: '/:lang(..)?/credentials' }} />
            <Redirect from="/:lang(..)?/hpe-tech-pro" to={{ pathname: '/:lang(..)?/technical-certifications/tech-pro' }} />
            <Redirect from="/:lang(..)?/get-certified" to={{ pathname: '/:lang(..)?/technical-certifications/get-certified' }} />
            <Redirect from="/:lang(..)?/keep-current" to={{ pathname: '/:lang(..)?/technical-certifications/keep-current' }} />
            <Redirect from="/:lang(..)?/portfolios" to={{ pathname: '/:lang(..)?/technical-certifications/portfolios' }} />
            <Redirect from="/:lang(..)?/technical-certifications-badges" to={{ pathname: '/:lang(..)?/technical-certifications/showcase' }} />
            <Redirect from="/:lang(..)?/hpe-sales-pro" to={{ pathname: '/:lang(..)?/sales-certifications/sales-pro' }} />
            <Redirect from="/:lang(..)?/sales-certifications-badges" to={{ pathname: '/:lang(..)?/sales-certifications/showcase' }} />

            <SecureRoute path='/:lang(..)?/sme/admin' component={SmeAdmin} />
            <SecureRoute path='/:lang(..)?/sme/application/:id?' component={SmeApplication} />

            <SecureRoute path='/:lang(..)?/locate-learnerId' component={LocateLearnerId} />
            <SecureRoute path='/:lang(..)?/unaffiliated-learner-reg' component={UnaffiliatedLearnerReg} />

            <SecureRoute path='/:lang(..)?/plmt/activities' component={PlmtActivities} />
            <SecureRoute path='/:lang(..)?/plmt/gap-summary' component={PlmtGapSummary} />
            <SecureRoute path='/:lang(..)?/plmt/gap-analysis/:region/:country/:signingEntityId/:program' component={PlmtGapAnalysis} />
            <SecureRoute path='/:lang(..)?/plmt/learning-assignment' component={LearningAssignment} />
            <SecureRoute path='/:lang(..)?/plmt/regional-reports' component={RegionalReports} />
            <SecureRoute path='/:lang(..)?/plmt/map-reports' component={MapReports} />
            <SecureRoute path='/:lang(..)?/plmt/distributors' component={Distributors} />
            <SecureRoute path='/:lang(..)?/plmt/manage-builder' component={ManageBuilder} />
            <SecureRoute exact path='/:lang(..)?/plmt' component={PlmtHome} />

            <SecureRoute path='/:lang(..)?/admin/learner-lookup' component={LearnerIdLookup} />

            <SecureRoute path='/:lang(..)?/cic/:activeTab?' component={CertifiedInstructorConnection} />

            <SecureRoute path='/:lang(..)?/lpc/manage-affiliations' component={AffiliationManagement} />
            <SecureRoute path='/:lang(..)?/lpc/instructor-registration' component={InstructorRegistration} />
            <SecureRoute path='/:lang(..)?/lpc/instructor-finder' component={InstructorFinder} />
            <SecureRoute path='/:lang(..)?/lpc/:activeTab?' component={LearningPartnerConnection} />

            <Route path='/:lang(..)?/' component={Overview} />
          </Switch>
        </Layout>
      </Security>);
    }}</ClearBrowserCache>);
  }
}

export default connect(
  (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
  mapDispatchToProps)(withRouter(App as any));
