import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../store';
import { KeyValuePair } from '../models/Common';
import { Box, Paragraph, ResponsiveContext, Image, Heading, Text, Button, Anchor } from 'grommet';
import { retrieveLocalizedStrings, siteSlice } from '../store/Site';
import BasePage from '../components/BasePage';
import parse from 'html-react-parser';
import _ from 'lodash';
import HtmlComponent from '../components/HtmlComponent';

const mapDispatchToProps = {
    retrieveLocalizedStrings,
    ...siteSlice.actions,
}

interface IHpePressProps {
}

interface HpePressState {
}

type HpePressProps = IHpePressProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class HpePress extends React.PureComponent<HpePressProps, HpePressState> {
    constructor(props: HpePressProps) {
        super(props);

        this.state = {
        };

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'ExternalPages.HomePage_Welcome',
            'Metadata.HpePressKeywords,', 'Metadata.HpePressDescription', 'Datacard.HPE_Learner_ID_Label',
            'ExternalPages.HpePress_Intro_Statement', 'ExternalPages.HprePres_Label', 'ExternalPages.HprePress_Button_Label',
            'ExternalPages.HpePress_StudyGuides_Title', 'ExternalPages.HpePress_StudyGuides_Statement', 'ExternalPages.HpePress_StudyGuides_Button1_Label',
            'ExternalPages.HpePress_StudyGuides_Button2_Label', "ExternalPages.HpePress_Banner_Statement", "ExternalPages.HpePress_Banner_Statement_Author",
            'ExternalPages.HpePress_Banner_Statement_Author_Title',
            'ExternalPages.HpePress_PracticeTest_Title', 'ExternalPages.HpePress_PracticeTest_Statement', 'ExternalPages.HpePress_PracticeTest_Button_Label',
            'ExternalPages.HpePress_SelfDirectedLabs_Title', 'ExternalPages.HpePress_SelfDirectedLabs_Statement', 'ExternalPages.HpePress_SelfDirectedLabs_Button_Label',
            'ExternalPages.HPEPress_Footer_Quote', 'ExternalPages.HPEPress_Footer_Quote_Author', 'ExternalPages.HPEPress_Footer_Quote_Author_Title'];

        this.props.setActiveMenu('resources-menu');
        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.setLoading(true);
    }

    public componentDidMount() {
        this.props.setLoading(false);
    }

    public render() {
        let site = this.props.site;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['Datacard.Resources_Label'], value: "" },
            { key: site.menuStrings['Datacard.HPE_Press_Books_Label'], value: "" }
        ];
        let title = site.localizedStrings['ExternalPages.HprePres_Label'];

        return <ResponsiveContext.Consumer>
            {size => (
                <BasePage breadcrumbs={breadcrumbs} title={title} pageName="HPE_Press" {...this.props}
                    metadataKeywords={site.localizedStrings['Metadata.HpePressKeywords']}
                    metadataDescription={site.localizedStrings['Metadata.HpePressDescription']} >
                    {site.stringsLoaded && <Box gap="medium">
                        <Box fill="horizontal">
                            <Heading textAlign="center" fill >{(title ?? "")}</Heading>
                        </Box>
                        <Box alignSelf="center" width="xlarge">
                            <Paragraph textAlign="center" fill>
                                {site.localizedStrings['ExternalPages.HpePress_Intro_Statement']}
                            </Paragraph>
                        </Box>
                        <Box alignSelf="center" width="small">
                            <Button primary href="https://hpepress.hpe.com/" target="_blank" hoverIndicator={{ color: "black" }}
                                label={site.localizedStrings['ExternalPages.HprePress_Button_Label']} />
                        </Box>
                        <Box gap="large" pad={{ top: "large" }}>
                            <Box direction="row-responsive" fill gap="large" pad={{ left: "large", right: "large" }} >
                                <Box fill alignSelf="center">
                                    <Image fit="contain" src="images/hpe-press/HPE202208310122986_1600_0_72_RGB.jpg" />
                                </Box>
                                <Box fill pad="small" alignSelf="center">
                                    <Box pad={{ bottom: "small" }}>
                                        <Text weight="bold" size="xlarge">{site.localizedStrings['ExternalPages.HpePress_StudyGuides_Title']}</Text>
                                    </Box>
                                    <Paragraph fill>{parse(site.localizedStrings['ExternalPages.HpePress_StudyGuides_Statement'])}</Paragraph>
                                    <Anchor href="https://hpepress.hpe.com/catalog/See+all+titles-1" target="_blank" color="brand"
                                        label={site.localizedStrings['ExternalPages.HpePress_StudyGuides_Button1_Label']} />
                                </Box>
                            </Box>
                            <Box direction="row-responsive" fill gap="large" pad={{ left: "large", right: "large" }} >
                                <Box fill pad="small" alignSelf="center">
                                    <Box pad={{ bottom: "small" }}>
                                        <Text weight="bold" size="xlarge">
                                            {site.localizedStrings['ExternalPages.HpePress_PracticeTest_Title']}
                                        </Text>
                                    </Box>
                                    <Paragraph fill>{site.localizedStrings['ExternalPages.HpePress_PracticeTest_Statement']}</Paragraph>
                                    <Anchor href="https://hpepress.hpe.com/catalog/PracticeTests-169" target="_blank" color="brand"
                                        label={site.localizedStrings['ExternalPages.HpePress_PracticeTest_Button_Label']} />
                                </Box>
                                <Box fill alignSelf="center">
                                    <Image fit="contain" src="images/hpe-press/HPE202208310051099_1600_0_72_RGB.jpg" />
                                </Box>
                            </Box>
                            <Box direction="row-responsive" fill gap="large" pad={{ left: "large", right: "large" }} >
                                <Box fill alignSelf="center">
                                    <Image fit="contain" src="images/hpe-press/HPE202208310051287_1600_0_72_RGB.jpg" />
                                </Box>
                                <Box fill pad="small" alignSelf="center">
                                    <Box pad={{ bottom: "small" }}>
                                        <Text weight="bold" size="xlarge">
                                            {site.localizedStrings['ExternalPages.HpePress_SelfDirectedLabs_Title']}
                                        </Text>
                                    </Box>
                                    <Paragraph fill>
                                        {parse(site.localizedStrings['ExternalPages.HpePress_SelfDirectedLabs_Statement'])}
                                    </Paragraph>
                                    <Anchor href="https://hpepress.hpe.com/catalog/Self-Directed+Remote+Labs-168" target="_blank" color="brand"
                                        label={site.localizedStrings['ExternalPages.HpePress_SelfDirectedLabs_Button_Label']} />
                                </Box>
                            </Box>
                            <Box height="400px" gap="medium" pad="medium" justify="center"
                                background={{ size: "fit", image: "url('images/hpe-press/GettyImages-978721396_1600_0_72_RGB.jpg')" }}>
                                <Box width={{ max: "large" }} background="white" pad="small">
                                    <Paragraph fill size="large">
                                        <HtmlComponent html={site.localizedStrings['ExternalPages.HPEPress_Footer_Quote']} />
                                    </Paragraph>
                                    <Text weight="bold" size="large">{site.localizedStrings['ExternalPages.HPEPress_Footer_Quote_Author']}</Text>
                                    <Text size="small">{site.localizedStrings['ExternalPages.HPEPress_Footer_Quote_Author_Title']}</Text>
                                </Box>
                            </Box>
                        </Box>
                    </Box>}
                </BasePage>
            )}
        </ResponsiveContext.Consumer>;
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(HpePress as any));
