import React from 'react';
import { Box, Button, Text, Tip, Drop, Anchor, Spinner, TextInput, Form, DropButton, Image } from 'grommet';
import { Sync, SubtractCircle } from 'grommet-icons';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { siteSlice, login, switchUser } from '../store/Site';
import { ApplicationState } from '../store';
import { withOktaAuth } from '@okta/okta-react';
import { hasRole } from '../Utilities';
import LanguageMenu from './LanguageMenu';
import { TokenManagerInterface, AccessToken, IDToken, UserClaims } from '@okta/okta-auth-js';

const mapDispatchToProps = {
    switchUser,
    login,
    ...siteSlice.actions
}

interface ILoginProps {
    collapsed: boolean;
}

type LoginProps =
    ApplicationState // ... state we've requested from the Redux store
    & ILoginProps
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

interface ILoginState {
    showSwitch: boolean;
    impersonatedUser?: any;
    processing: boolean;
}

class Login extends React.PureComponent<LoginProps, ILoginState> {

    constructor(props: LoginProps) {
        super(props);

        this.state = {
            showSwitch: false,
            processing: false
        }
    }

    public componentDidMount() {
        this.checkUser();
    }

    public componentDidUpdate() {
        let site = this.props.site;

        this.checkUser();
        if (site.userInfo && site.user && this.state.processing) {
            this.setState({ processing: false });
        }
    }

    login = async () => {
        await this.props.oktaAuth.signInWithRedirect({ originalUri: window.location.href });
    }

    logout = async () => {
        this.props.setUserInfo(undefined);
        this.props.setUser(undefined);
        try {
            await this.props.oktaAuth.signOut({ postLogoutRedirectUri: `${process.env.REACT_APP_OKTA_BASE_REDIRECT_URI}${process.env.PUBLIC_URL}` });
        } catch (error) {
            this.props.setError(error)
        }
    }

    checkUser = async () => {
        let site = this.props.site;

        if (this.props.authState?.isAuthenticated) {
            if ((!site.userInfo || !site.user) && !this.state.processing) {
                this.setState({ processing: true });

                let authClient = this.props.oktaAuth;
                let tokenManager: TokenManagerInterface = authClient.tokenManager;
                let accessToken: AccessToken = await tokenManager.get('accessToken') as AccessToken;
                let idToken: IDToken = await tokenManager.get('idToken') as IDToken;
                let userInfo: UserClaims = await authClient.token.getUserInfo(accessToken, idToken);

                this.props.setUserInfo(userInfo);
                if (!site.user && !site.loadingUser) {
                    setTimeout(() => {
                        this.props.login(userInfo);
                    }, 100);
                }
            }
        } else {
            this.props.oktaAuth.session.exists().then((exists) => {
                if (exists) {
                    this.props.oktaAuth.token.getWithoutPrompt({
                        responseType: ['id_token', 'token', 'refresh_token'],
                    }).then((res) => {
                        this.props.oktaAuth.handleLoginRedirect(res.tokens);
                    });
                    this.props.oktaAuth.tokenManager.renew('idToken');
                }
            });
        };
    }

    onCloseDrop = () => {
        this.setState({ showSwitch: false });
    }

    onSwitchUser = () => {
        if (!this.props.site.actualUser) {
            this.props.setActualUser(this.props.site.user);
        }
        this.props.switchUser(this.state.impersonatedUser.email)
        this.setState({ showSwitch: false, impersonatedUser: undefined });
    }

    onShowSwitch = () => {
        this.setState({ showSwitch: true });
    }

    onStopSwitch = () => {
        let actualUwer = this.props.site.actualUser;

        this.props.setActualUser(undefined);
        this.props.setUser(actualUwer);
    }

    public render() {
        let site = this.props.site;
        let initials = site.userInfo
            ? `${site.userInfo.given_name?.charAt(0)}${site.userInfo.family_name?.charAt(0)}`
            : '';
        let hasSwitchUser = hasRole(site.user, ["SWITCH_USER"]);
        let isImpersonating = site.actualUser && site.user?.email !== site.actualUser?.email;

        return <Box>
            {site.userInfo && <Box align='center' justify='center' className="circle" >
                <DropButton label={<Text weight="bold" size="small">{initials}</Text>} dropAlign={{ top: 'bottom', left: 'left' }} onClose={this.onCloseDrop}
                    dropContent={
                        <Box pad="small" gap="small">
                            {site.loadingUser && <Image src="images/loader.svg" />}
                            {!site.user && !site.loadingUser && <Text>Unable to get user permissions</Text>}
                            {site.user && <Box gap="small">
                                <LanguageMenu />
                                {hasSwitchUser && <Box gap='small'>
                                    {!this.state.showSwitch && !isImpersonating &&
                                        <Anchor label={site.menuStrings['ExternalPages.SwitchUser_Label']} onClick={this.onShowSwitch} />}
                                    {this.state.showSwitch &&
                                        <Form value={this.state.impersonatedUser} onChange={nextValue => this.setState({ impersonatedUser: nextValue })}>
                                            <Box direction='row' gap='xsmall'>
                                                <TextInput name="email" />
                                                <Button type='submit' icon={<Sync size='small' />} onClick={this.onSwitchUser}
                                                    disabled={!this.state.impersonatedUser?.email} />
                                            </Box>
                                        </Form>}
                                </Box>}
                                {isImpersonating && <Box direction='row' gap='xsmall'>
                                    <Text>{`${site.user.firstName} ${site.user.lastName}`}</Text>
                                    <Button icon={<SubtractCircle size='small' />} onClick={this.onStopSwitch}></Button>
                                </Box>}
                            </Box>}
                            {site.userInfo && <Anchor label={site.menuStrings['ExternalPages.Logout_Label']} onClick={this.logout} />}
                        </Box>
                    } />
            </Box>}
            {!this.props.authState?.isAuthenticated && <Box width={{ min: 'xsmall' }} round={true} hoverIndicator onClick={this.login}
                border={{ color: "brand", size: "small", side: "all" }}>
                <Text textAlign="center" weight="bold">{site.menuStrings['ExternalPages.Login_Label']}</Text>
            </Box>}
        </Box>
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(withOktaAuth(Login as any)));
